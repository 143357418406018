import { useState } from 'react';
import Button from '@truescope-web/react/lib/components/form/Button';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import { extractError } from '../../components/Api';
import { useApiLookup } from '../../components/ApiLookupProvider';
import { deregister, register } from './api';
import { variants } from './constants';

const RegisterButton = ({ variant, socialHandle, onChange, location }) => {
	const { showSnackbar } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [getDatahubApi] = useApiLookup();

	const handleRegisterClick = async () => {
		try {
			setIsLoading(true);
			const [updatedHandle] = await register(getDatahubApi, [socialHandle], location);
			onChange(updatedHandle);
		} catch (e) {
			const msg = extractError(e);
			showSnackbar(msg, snackbarVariants.error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleDeregisterClick = async () => {
		try {
			setIsLoading(true);
			const [updatedHandle] = await deregister(getDatahubApi, [socialHandle]);
			onChange(updatedHandle);
		} catch (e) {
			showSnackbar(extractError(e), snackbarVariants.error);
		} finally {
			setIsLoading(false);
		}
	};

	switch (variant) {
		case variants.disabled:
			return (
				<Button variant="default" disabled>
					Register
				</Button>
			);
		case variants.registered:
			return (
				<Button variant="error" onClick={handleDeregisterClick} loading={isLoading}>
					Deregister
				</Button>
			);
		case variants.unregistered:
			return (
				<Button variant="confirm" onClick={handleRegisterClick} loading={isLoading}>
					Register
				</Button>
			);
		default:
			throw new Error(`unknown variant '${variant}'`);
	}
};

export default RegisterButton;
