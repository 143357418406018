import DataGrid, { Column, FilterRow, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';
import React, { useMemo, useRef, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Notification from '@truescope-web/react/lib/components/layout/Notification';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import { isProduction } from '@truescope-web/utils/lib/constants';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { workspaceUserStatesLookup } from '@truescope-web/utils/lib/workspaces';
import { useApiLookup } from '../../../../../components/ApiLookupProvider';
import { formatPagingInfoText, renderEmailCell, renderOptionsCell } from '../../../../../components/Dx/DxGridConstants';
import { removeInvite, sendInvites } from './api';
import { createInvitesDataSource } from './constants';

const InvitesTable = ({ workspaceInvites, setWorkspaceInvites, workspaceUsers, workspaceId, workspacePlan }) => {
	const { showSnackbar } = useSnackbar();
	const [getDatahubApi] = useApiLookup();
	const [anchorEl, setAnchorEl] = useState(null);
	const dataSource = useMemo(() => createInvitesDataSource(workspaceInvites), [workspaceInvites]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const dataGridRef = useRef(null);

	const handleResendInviteClick = async () => {
		handleCloseMenu();

		const activeUserCount = workspaceUsers.filter((user) => user.workspace_user_state_id === workspaceUserStatesLookup.active).length;
		if (activeUserCount >= workspacePlan.max_seats) {
			showSnackbar('The maximum active user count has been reached for this workspace.', snackbarVariants.warning);
			return;
		}

		dataGridRef.current._instance.beginCustomLoading();
		try {
			const { message, invites: newInvites } = await sendInvites(getDatahubApi, workspaceId, [selectedUser.email]);
			if (!stringIsNullOrEmpty(message)) {
				throw new Error(`Failed to resend invite - ${message}`);
			}
			const updatedInvite = newInvites.find((newInvite) => newInvite.email === selectedUser.email);
			if (isNullOrUndefined(updatedInvite)) {
				throw new Error('Did not resend invite');
			}
			setWorkspaceInvites((prev) => {
				const index = prev.findIndex((invite) => invite.email === selectedUser.email);
				prev[index] = { ...updatedInvite };
				return [...prev];
			});
			showSnackbar('Invitation has been succesfully resent', snackbarVariants.success);
		} catch (e) {
			showSnackbar(e.message, snackbarVariants.error);
		} finally {
			dataGridRef.current?._instance?.endCustomLoading();
		}
	};

	const handleCopyToClipboardClick = () => {
		handleCloseMenu();
		const guid = selectedUser.guid;
		if (stringIsNullOrEmpty(guid)) {
			showSnackbar('Failed to copy invite link', snackbarVariants.error);
			return;
		}

		const link = isProduction() ? `https://app.truescope.com/join/${guid}` : `https://app.dev2.truescope.cloud/join/${guid}`;

		navigator.clipboard.writeText(link);
		showSnackbar('Copied to Clipboard', snackbarVariants.success);
	};

	const handleRemoveInvitationClick = (_e) => {
		handleCloseMenu();
		dataGridRef.current._instance.beginCustomLoading();
		setIsLoading(true);
		removeInvite(getDatahubApi, workspaceId, selectedUser.email)
			.then(({ message }) => {
				if (!stringIsNullOrEmpty(message)) {
					throw new Error(message);
				}
				setWorkspaceInvites((prev) => prev.filter(({ email }) => email !== selectedUser.email));
			})
			.catch((e) => setErrorMessage(`Failed to remove invite ${e.message}`))
			.finally(() => {
				setIsLoading(false);
				dataGridRef.current._instance.endCustomLoading();
			});
	};

	const handleCloseMenu = (_e) => {
		setAnchorEl(null);
	};

	const handleOpenMenu = (e, selected) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(e.currentTarget);
		setSelectedUser(selected);
	};

	const renderMessage = () => {
		if (!isNullOrUndefined(errorMessage)) {
			return (
				<Grid item>
					<Typography variant="subtitle">{errorMessage}</Typography>
				</Grid>
			);
		}
	};

	const renderMenu = () => {
		return (
			<Menu id="row-menu" anchorEl={anchorEl} keepMounted open={!isNullOrUndefined(anchorEl)} onClose={handleCloseMenu}>
				<MenuItem onClick={handleCopyToClipboardClick}>
					<Typography>Copy Invite Link</Typography>
				</MenuItem>
				<MenuItem onClick={handleResendInviteClick}>
					<Typography>Resend Invitation</Typography>
				</MenuItem>
				<MenuItem onClick={handleRemoveInvitationClick}>
					<Typography>Delete Invitation</Typography>
				</MenuItem>
			</Menu>
		);
	};

	const renderStatusCell = ({ data, value }) => {
		return (
			<div className="wrap">
				<Notification hideIcon center variant={data.statusVariant}>
					{value}
				</Notification>
			</div>
		);
	};

	const renderDateCell = ({ data }) => {
		return (
			<div className="wrap">
				<Typography>{data.expiration_string}</Typography>
			</div>
		);
	};

	const renderDataGrid = () => {
		return (
			<DataGrid
				id="story-table"
				ref={dataGridRef}
				showRowLines={true}
				dataSource={dataSource}
				showColumnLines={false}
				showBorders={true}
				onContentReady={formatPagingInfoText}
			>
				<FilterRow visible={true} applyFilter="auto" />

				<HeaderFilter visible={true} />

				<Column caption="Email Address" dataField="email" cssClass="cell cell--pad clickable" cellRender={renderEmailCell}>
					<HeaderFilter allowSearch={true} />
				</Column>

				<Column
					caption="Expires"
					dataField="expiration"
					cssClass="cell cell--pad clickable"
					allowFiltering={false}
					allowSorting={false}
					allowSearch={false}
					cellRender={renderDateCell}
				>
					<HeaderFilter allowSearch={true} />
				</Column>

				<Column
					caption="Status"
					dataField="statusLabel"
					width={120}
					cssClass="cell cell--pad clickable"
					allowFiltering={false}
					allowSorting={false}
					allowSearch={false}
					cellRender={renderStatusCell}
				>
					<HeaderFilter allowSearch={true} />
				</Column>

				<Column
					dataField="id"
					width={60}
					cssClass="cell cell--center"
					alignment="center"
					caption=""
					cellRender={(e) => renderOptionsCell(e, handleOpenMenu, null, isLoading)}
					allowFiltering={false}
					allowSearch={false}
					allowSorting={false}
				/>

				<Paging defaultPageSize={10} />

				<Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} />
			</DataGrid>
		);
	};

	return (
		<>
			<Grid container>
				<Grid item>{renderDataGrid()}</Grid>
				{renderMessage()}
			</Grid>
			{renderMenu()}
		</>
	);
};

export default InvitesTable;
