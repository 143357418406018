import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Select from '@truescope-web/react/lib/components/form/Select';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Icon from './Icon';
import RegisterButton from './RegisterButton';
import { countryOptions, getVariant, variants } from './constants';

const SocialHandle = ({ socialHandle, onChange, location: _initialLocation }) => {
	const variant = getVariant(socialHandle);
	const [location, setLocation] = useState(); //todo:we may need to use _initialLocation as the default value

	return (
		<div className={`social-handle social-handle--${variant}`}>
			<div className="social-handle__container social-handle__container--grow">
				<Icon variant={variant} />
				<Typography variant="body">{socialHandle['social-network']}</Typography>
				<div>
					<Tooltip title={`View ${socialHandle['social-network']}`}>
						<a href={socialHandle.url} target="_blank" rel="noopener noreferrer">
							<Typography variant="subtitle">@{socialHandle.handle}</Typography>
						</a>
					</Tooltip>
				</div>
			</div>
			<div className="social-handle__container">
				{!socialHandle.is_registered && (
					<Select
						placeholder="Register without location"
						options={countryOptions}
						value={location}
						onChange={(_e, _value, rawValue) => setLocation(rawValue)}
						disabled={variant === variants.disabled}
						clearable
					/>
				)}
				<div className="social-handle__container__button">
					<RegisterButton variant={variant} socialHandle={socialHandle} onChange={onChange} location={location?.value} />
				</div>
			</div>
		</div>
	);
};

export default SocialHandle;
