import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { isProduction } from '@truescope-web/utils/src/constants';

const searchMetadataApiUrl = isProduction()
	? 'https://search-metadata.prod.truescope.cloud'
	: 'https://search-metadata.dev2.truescope.cloud';

const post = async (getDatahubApi, apiPath, payload, apiAction) => {
	const datahubApi = await getDatahubApi();

	const response = await fetch(`${searchMetadataApiUrl}/${apiPath}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			//we pass the google token to the api so that it can be used for auditing
			Authorization: datahubApi.defaults.headers.Authorization
		},
		body: JSON.stringify(payload)
	});

	if (!response.ok) {
		const msg = `Failed to ${apiAction} - ${response.data}`;
		console.error(msg, response);
		throw new Error(msg);
	}

	const data = await response.json();
	return data;
};

/**
 * searches for handles
 */
export const search = async (getDatahubApi, keyword, location, language) => {
	const data = await post(
		getDatahubApi,
		'checkhandles',
		[
			{
				keyword,
				location,
				language
			}
		],
		'search'
	);
	return data;
};

/**
 * registers handles
 */
export const register = async (getDatahubApi, handles, location) => {
	const data = await post(
		getDatahubApi,
		'registerhandles',
		stringIsNullOrEmpty(location) ? handles : handles.map((handle) => ({ ...handle, location })),
		'register'
	);
	return data;
};

/**
 * degisters handles
 */
export const deregister = async (getDatahubApi, handles) => {
	const data = await post(getDatahubApi, 'registerhandles?deregister=true', handles, 'deregister');
	return data;
};
