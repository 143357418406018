import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Link from '@mui/material/Link';
import ArticlePanel from '@truescope-web/react/lib/components/layout/ArticlePanel';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import SkeletonWrapper from '@truescope-web/react/lib/components/loading/SkeletonWrapper';
import Sheet from '@truescope-web/react/lib/components/modal/SheetV2';
import FacebookEmbed from '@truescope-web/react/lib/components/widgets/FacebookEmbed';
import InstagramEmbed from '@truescope-web/react/lib/components/widgets/InstagramEmbed';
import RedditEmbed from '@truescope-web/react/lib/components/widgets/RedditEmbed';
import Sentiment from '@truescope-web/react/lib/components/widgets/Sentiment';
import SourceLogo from '@truescope-web/react/lib/components/widgets/SourceLogo';
import TikTokEmbed from '@truescope-web/react/lib/components/widgets/TikTokEmbed';
import TvEyesMediaPlayer from '@truescope-web/react/lib/components/widgets/TvEyesMediaPlayer';
import TwitterEmbed from '@truescope-web/react/lib/components/widgets/TwitterEmbed/TwitterEmbed';
import YouTubeEmbed from '@truescope-web/react/lib/components/widgets/YouTubeEmbed';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isBroadcastFromSupplier, mediaTypesLookup } from '@truescope-web/utils/lib/mediaTypes';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { suppliersLookup } from '@truescope-web/utils/lib/suppliers';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import Author from '../../Entities/Authors/Author';
import Company from '../../Entities/Companies/Company';
import Person from '../../Entities/People/Person';
import Source from '../../Entities/Sources/Source';
import MediaItemAccordian from './MediaItemAccordian';
import { getMediaItem, updateMediaItem } from './MediaItemConstants';

const MediaItem = ({ match, document_id: did, onClose }) => {
	const history = useHistory();
	const [getDatahubApi] = useApiLookup();
	const [mediaItem, setMediaItem] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [isMediaItemLoading, setIsMediaItemLoading] = useState(false);
	const [showSourceSheet, setShowSourceSheet] = useState(false);
	const [showAuthorSheet, setShowAuthorSheet] = useState(false);
	const [showCompanySheet, setShowCompanySheet] = useState(false);
	const [showPersonSheet, setShowPersonSheet] = useState(false);
	const [entityId, setEntityId] = useState(false);

	const documentId = !stringIsNullOrEmpty(did) ? did : match.params.document_id;

	const loadMediaItem = useCallback(async () => {
		setIsMediaItemLoading(true);
		try {
			const { mediaItem, message } = await getMediaItem(getDatahubApi, { document_id: documentId });
			if (!stringIsNullOrEmpty(message)) {
				throw new Error(message);
			}
			setMediaItem(mediaItem);
		} catch (e) {
			console.error(`failed to load media item ${documentId} - ${e.message}`);
			setErrorMessage(e.message);
		} finally {
			setIsMediaItemLoading(false);
		}
	}, [getDatahubApi, documentId, setMediaItem, setErrorMessage, setIsMediaItemLoading]);

	useEffect(() => {
		loadMediaItem();
	}, [loadMediaItem]);

	const updateItem = async (mediaItem) => {
		try {
			await updateMediaItem(getDatahubApi, mediaItem);
		} catch (e) {
			console.error('Error updating media item', e);
		}
	};

	const handleAuthorClick = (id) => {
		setEntityId(id);
		setShowAuthorSheet(true);
	};

	const handleCloseAuthorSheet = useCallback(() => {
		setShowAuthorSheet(false);
		setEntityId(null);
	}, [setShowAuthorSheet, setEntityId]);

	const handleCloseCompanySheet = useCallback(() => {
		setShowCompanySheet(false);
		setEntityId(null);
	}, [setShowCompanySheet, setEntityId]);

	const handleClosePersonSheet = useCallback(() => {
		setShowPersonSheet(false);
		setEntityId(null);
	}, [setShowPersonSheet, setEntityId]);

	const handleClickItemId = () => {
		onClose();
		history.push(`/media/search?query=id:${documentId}`);
	};

	const renderArticleImage = ({ article_media }) => {
		if (isNullOrUndefined(article_media)) {
			return null;
		}

		const { caption, image_url } = article_media;

		if (!stringIsNullOrEmpty(image_url)) {
			return (
				<Grid item>
					<img className="full-width" src={image_url} alt={caption || 'article logo'} />
				</Grid>
			);
		}

		return null;
	};

	const renderSummaryPanel = (mediaItem) => {
		const publicationDate = moment(mediaItem.publication_date, 'YYYY-MM-DD HH:mm:ss');

		return (
			<Grid item>
				<ArticlePanel
					htmlPreview={`<b>${publicationDate.format('DD MMMM YYYY h:mm:ssa')}</b> - ${mediaItem.summary || 'No Summary'}`}
					htmlContent={mediaItem.body || 'No Body'}
				/>
			</Grid>
		);
	};

	const renderMediaPlayer = (articleMedia) => {
		if (isNullOrUndefined(articleMedia)) {
			return null;
		}

		const { video_url } = articleMedia;

		if (stringIsNullOrEmpty(video_url)) {
			return null;
		}

		return (
			<Grid item>
				<TvEyesMediaPlayer url={video_url} allowFullScreen />
			</Grid>
		);
	};

	const renderTwitterPanel = (supplierInfo) => {
		if (isNullOrUndefined(supplierInfo)) {
			return null;
		}
		//TODO: When datahub theming is done - update theme below to either 'light' or 'dark' based on current theme
		return (
			<Grid item>
				<TwitterEmbed tweetId={supplierInfo.key} theme="light" />
			</Grid>
		);
	};

	const renderFacebookPanel = (postUrl) => {
		if (isNullOrUndefined(postUrl)) {
			return null;
		}

		/*
		 * Note that Facebook's support of darkmode themes is patchy at best,
		 * so we are not explicitly setting the theme value on our FacebookEmbed
		 * at this time
		 */
		return (
			<Grid item>
				<FacebookEmbed appId={2831204160309171} postUrl={postUrl} />
			</Grid>
		);
	};

	const renderInstagramPanel = (postUrl) => {
		if (isNullOrUndefined(postUrl)) {
			return null;
		}

		return (
			<Grid item>
				<InstagramEmbed postUrl={postUrl} />
			</Grid>
		);
	};

	const renderYouTube = (mediaItem) => {
		const { title, supplier_info } = mediaItem;
		return (
			<>
				{!isNullOrUndefined(supplier_info.key) && (
					<Grid item>
						<YouTubeEmbed youtubeId={supplier_info.key} title={title} />
					</Grid>
				)}
				{renderSummaryPanel(mediaItem)}
			</>
		);
	};

	const renderTikTokPanel = (postUrl) => {
		if (isNullOrUndefined(postUrl)) {
			return null;
		}

		return (
			<Grid item>
				<TikTokEmbed postUrl={postUrl} />
			</Grid>
		);
	};

	const renderRedditPanel = (postUrl) => {
		return (
			<Grid item>
				<RedditEmbed
					postUrl={postUrl}
					theme="light"
					showTitle
					showMedia
					showMoreLink
					includeParent
					maxReplyTreeDepth={2}
					allowScrolling
				/>
			</Grid>
		);
	};

	const renderAvatar = ({ source, source_name, ...mediaItem }) => {
		let subtitle = null;
		if (arrayIsNullOrEmpty(mediaItem.authors)) {
			subtitle = 'No Author';
		} else {
			subtitle = (
				<>
					{mediaItem.authors.map(({ author_id, name }) => (
						<Link className="clickable" key={author_id} onClick={() => handleAuthorClick(author_id)}>
							{name}
						</Link>
					))}
				</>
			);
		}

		if (!isNullOrUndefined(source.profile_image_url)) {
			return (
				<SourceLogo
					imageUrl={source.profile_image_url}
					sourceName={source_name}
					size="md"
					onTitleClick={() => setShowSourceSheet(true)}
					subtitle={subtitle}
				/>
			);
		}

		return (
			<SourceLogo
				sourceUrl={source.source_url}
				sourceName={source_name}
				size="md"
				onTitleClick={() => setShowSourceSheet(true)}
				subtitle={subtitle}
			/>
		);
	};

	const renderMediaSection = (mediaItem) => {
		const { media_type } = mediaItem.source;

		if (isBroadcastFromSupplier(media_type, mediaItem.supplier_info?.id, suppliersLookup.tveyes)) {
			return renderMediaPlayer(mediaItem.article_media);
		}

		switch (media_type) {
			case mediaTypesLookup.twitter:
				return renderTwitterPanel(mediaItem.supplier_info);
			case mediaTypesLookup.facebook:
				return renderFacebookPanel(mediaItem.item_url);
			case mediaTypesLookup.instagram:
				return renderInstagramPanel(mediaItem.item_url);
			case mediaTypesLookup.youtube:
				return renderYouTube(mediaItem);
			case mediaTypesLookup.tiktok:
				return renderTikTokPanel(mediaItem.item_url);
			case mediaTypesLookup.reddit:
				return renderRedditPanel(mediaItem.item_url);
			default:
				//This also handles undefined media_type (but that should never happen)
				return (
					<>
						{renderArticleImage(mediaItem)}
						{renderSummaryPanel(mediaItem)}
					</>
				);
		}
	};

	if (isMediaItemLoading) {
		return (
			<div className="full-width">
				<SkeletonWrapper />
			</div>
		);
	}

	if (!stringIsNullOrEmpty(errorMessage)) {
		return (
			<Grid container>
				<Grid item>{errorMessage}</Grid>
			</Grid>
		);
	}

	return (
		<>
			{!isNullOrUndefined(mediaItem) && (
				<Grid container id="media-item">
					<Grid item>
						<Typography variant="h4">
							{stringIsNullOrEmpty(mediaItem.item_url) ? (
								mediaItem.title
							) : (
								<Link
									className="heading-colour"
									href={mediaItem.item_url}
									target="_blank"
									rel="nofollow noopener noreferrer"
								>
									{mediaItem.title}
								</Link>
							)}
						</Typography>
					</Grid>

					<Grid item>
						<Inline>
							<Inline responsiveWrap={false}>{renderAvatar(mediaItem)}</Inline>
							<Inline horizontalAlignment={horizontalAlignment.right}>
								<Sentiment value={mediaItem.sentiment} />
							</Inline>
						</Inline>
					</Grid>

					{renderMediaSection(mediaItem)}

					<Grid item>
						<MediaItemAccordian
							mediaItem={mediaItem}
							setMediaItem={setMediaItem}
							setEntityId={setEntityId}
							setShowCompanySheet={setShowCompanySheet}
							setShowPersonSheet={setShowPersonSheet}
							updateItem={updateItem}
						/>
					</Grid>
					<Grid item className="item-id-container">
						<Typography>
							ID:{' '}
							<Link className="item-id-container__item-id" onClick={handleClickItemId}>
								{mediaItem.id}
							</Link>
						</Typography>
					</Grid>
				</Grid>
			)}
			<Sheet isOpen={showSourceSheet} onClose={() => setShowSourceSheet(false)}>
				<div className="sheet-content">
					<Source source_id={mediaItem?.source?.source_id} />
				</div>
			</Sheet>
			<Sheet isOpen={showAuthorSheet} onClose={handleCloseAuthorSheet}>
				<div className="sheet-content">
					<Author author_id={entityId} />
				</div>
			</Sheet>
			<Sheet isOpen={showPersonSheet} onClose={handleClosePersonSheet}>
				<div className="sheet-content">
					<Person person_id={entityId} />
				</div>
			</Sheet>
			<Sheet isOpen={showCompanySheet} onClose={handleCloseCompanySheet}>
				<div className="sheet-content">
					<Company company_id={entityId} />
				</div>
			</Sheet>
		</>
	);
};

export default MediaItem;
