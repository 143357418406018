import React from 'react';
import { Link } from '@mui/material';
import Sentiment from '@truescope-web/react/lib/components/widgets/Sentiment';
import { validationResults } from '@truescope-web/react/lib/utils/validation';
import { arrayIsNullOrEmpty, firstOrDefault } from '@truescope-web/utils/lib/arrays';
import { extractedEntityTypes } from '@truescope-web/utils/lib/entityHelpers';
import { isBroadcast } from '@truescope-web/utils/lib/mediaTypes';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { abbreviateNumber, isValidUrl, joinDefined, sortByString, stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { handleError } from '../../../components/Api.js';

export const mediaItemValidationResults = {
	channel: ({ channel }) => (isNullOrUndefined(channel) ? validationResults.required() : validationResults.ok()),
	date: ({ date }) => (stringIsNullOrEmpty(date) ? validationResults.required() : validationResults.ok()),
	title: ({ title }) => (stringIsNullOrEmpty(title) ? validationResults.required() : validationResults.ok()),
	print_clip: ({ isPrintItem, print_clip }) => {
		if (isPrintItem && isNullOrUndefined(print_clip)) {
			return validationResults.error('Please upload a print clipping.');
		}
		return validationResults.ok();
	},
	source_url: ({ isPrintItem, source_url }) => {
		if (isPrintItem) {
			return validationResults.ok();
		}
		if (stringIsNullOrEmpty(source_url)) {
			return validationResults.error('Please enter a valid url including http:// or https://');
		}
		return isValidUrl(source_url)
			? validationResults.ok()
			: validationResults.error('Please enter a valid url including http:// or https://');
	}
};

export const getMediaItem = (getDatahubApi, { document_id }) => {
	return getDatahubApi().then((api) =>
		api
			.get(`/documents/v1/${document_id}`)
			.then(({ data }) => {
				let { mediaItem } = data;
				let article_media;

				try {
					if (!arrayIsNullOrEmpty(mediaItem.media)) {
						const image = mediaItem.media.find(({ type }) => type === 'Image');
						if (!isNullOrUndefined(image)) {
							article_media = {
								caption: 'article cover',
								image_url: image.url
							};
						}
					} else if (!arrayIsNullOrEmpty(mediaItem.images)) {
						//note: old code. mediaItem.images has been removed. This may not even matter in a month (by 24/05/2020)
						if (mediaItem.images.length === 1) {
							article_media = mediaItem.images[0];
						} else {
							const images = mediaItem.images
								.map(({ image_url, caption }) => {
									if (stringIsNullOrEmpty(image_url)) {
										return { order: -1 };
									}

									let lc = image_url.toLowerCase();
									if (lc.indexOf('thumbnail') >= 0) {
										return { image_url, caption, order: 5 };
									}

									if (lc.indexOf('pixel') >= 0) {
										return { image_url, caption, order: 4 };
									}

									if (lc.indexOf('image') < 0) {
										return { image_url, caption, order: 3 };
									}

									return { image_url, caption, order: 0 };
								})
								.filter(({ order }) => order >= 0);
							images.sort((a, b) => a.order - b.order);
							article_media = images[0]; // This is the full 'image' object!
						}
					} else if (isBroadcast(mediaItem.source.media_type)) {
						const rawUrl = mediaItem.mediaview_url;
						const urlNoProtocol = `https://${rawUrl.replace(/^https?:\/\//i, '')}`; //force https
						article_media = {
							video_url: urlNoProtocol,
							caption: `Play Snippet`
						};
					}

					//sort entities by most hits
					if (!arrayIsNullOrEmpty(mediaItem.extracted_entities)) {
						mediaItem.extracted_entities.sort((a, b) => b.count - a.count);
					}

					//sort annotations by type then name
					if (!arrayIsNullOrEmpty(mediaItem.annotations)) {
						sortByString(mediaItem.annotations, 'name');
					}

					(mediaItem.workspaces || []).forEach((workspace) => {
						// Older items may not have triggered_by object
						if (isNullOrUndefined(workspace.triggered_by)) {
							workspace.triggered_by = {
								query_ids: [],
								scope_ids: []
							};
							return;
						}

						if (arrayIsNullOrEmpty(workspace.triggered_by.scope_ids)) {
							workspace.triggered_by.scope_ids = [];
						}

						if (arrayIsNullOrEmpty(workspace.triggered_by.query_ids)) {
							workspace.triggered_by.query_ids = [];
						}
					});

					mediaItem.source_media_type = mediaItem.source.media_type;

					// Source/Section name for broadcast media types
					const { section_name, source_name } = mediaItem.source;
					mediaItem.source_name = buildDefaultSourceName(source_name, section_name, mediaItem.source_media_type);
				} catch (e) {
					console.error('failed to deserialize media item', e.message, e.stack);
				}

				return {
					mediaItem: {
						...mediaItem,
						article_media
					}
				};
			})
			.catch((e) => handleError(`Failed to load document ${document_id}`, e, { mediaItem: {} }))
	);
};

export const updateMediaItem = (getDatahubApi, mediaItem) => {
	// Clean up properties we don't need
	delete mediaItem.source_media_type;
	mediaItem.queries.map((query) => {
		delete query.metadata;
		return query;
	});

	return getDatahubApi().then((api) =>
		api
			.patch(`/documents/v1`, { document: mediaItem })
			.then(({ data }) => ({ mediaItem: data }))
			.catch((e) => handleError(`Failed to update document ${mediaItem.id}`, e))
	);
};

export const createMediaItem = async (getDatahubApi, mediaItem) => {
	const { image_url, print_clip, channel, author, date, title, summary, body, source_url, media_type_id, sentiment } = mediaItem;
	const payload = {
		image_url,
		print_clip,
		channel,
		author,
		date,
		title,
		summary,
		body,
		source_url,
		media_type_id,
		sentiment
	};
	const api = await getDatahubApi();
	const response = await api.put(`/documents/v1/incoming`, payload);
	return response.data;
};

export const annotationTableFields = [
	{
		label: 'Annotation',
		property: 'name'
	},
	{
		label: 'Score',
		property: 'score'
	}
];

export const scopeTableFields = [
	{
		label: 'Feed',
		property: 'name'
	},
	{
		label: 'Score',
		property: 'score'
	}
];

export const queryTableFields = [
	{
		label: 'Query',
		property: 'name'
	},
	{
		label: 'Score',
		property: 'score'
	}
];

export const entityTypeLookup = {
	query: 'query',
	scope: 'scope'
};

export const entitySelectTypeLookup = {
	Feed: 'selectedScope',
	Query: 'selectedQuery'
};

export const audienceTableFields = [
	{
		label: 'Country',
		property: 'country_code',
		render: (value) => (value === 'GG' ? 'Global' : value)
	},
	{
		label: 'Unique Visitors',
		property: 'unique_visitors',
		render: (value) => abbreviateNumber(value)
	},
	{
		label: 'Visitors',
		property: 'visits',
		render: (value) => abbreviateNumber(value)
	},
	{
		label: 'Page Views',
		property: 'page_views',
		render: (value) => abbreviateNumber(value)
	}
];

export const getEntityTableFields = (handleClick) => {
	return [
		{
			label: 'Entity Type',
			property: 'type'
		},
		{
			label: 'Entity',
			property: 'text',
			render: (value, data) => {
				if (data.extracted_entity_type_id === extractedEntityTypes.location) {
					return value;
				}
				return (
					<Link className="clickable" onClick={() => handleClick(data.extracted_entity_id, data.extracted_entity_type_id)}>
						{value}
					</Link>
				);
			}
		},
		{
			label: 'Sentiment',
			property: 'sentiment',
			render: (value) => <Sentiment value={value} />
		},
		{
			label: 'Count',
			property: 'count'
		}
	];
};

/**
 * Get scope/query options (exclude the ones are already selected)
 * @param {*} configOptions - config.scopeOptions | config.queryOptions
 * @param {*} currentEntities - mediaItem.scopes | mediaItem.queries
 * @param {*} entityType - scope | query
 */
export const getUpdatedSelectOptions = (configOptions, currentEntities, entityType) => {
	if (arrayIsNullOrEmpty(currentEntities)) {
		return configOptions;
	}
	const ids = currentEntities.map((entity) => entity[`${entityType}_id`]);
	return configOptions.filter((entity) => !ids.includes(entity.value));
};

/**
 * Get selected entity
 * @param {*} id - selected id - scope_id | query_id
 * @param {*} configOptions - config.scopeOptions | config.queryOptions
 * @param {*} currentEntities - mediaItem.scopes/queries
 * @param {*} entityType - scope | query
 * @param {*} withMetaData - mediaItem.metadata
 */
export const getSelectedEntity = (id, configOptions, currentEntities, entityType, withMetaData = false) => {
	if (!selectedIdIsValid(currentEntities, id, entityType)) {
		return;
	}

	const selectedOption = getSelectedOptionById(id, configOptions);
	if (!isNullOrUndefined(selectedOption)) {
		const result = {
			created_date: null,
			highlight: null,
			item_date: null,
			keyword_matches: [],
			score: selectedOption?.metadata.score || 1,
			[`${entityType}_id`]: id,
			name: selectedOption?.metadata.name
		};

		if (withMetaData) {
			result.metadata = selectedOption.metadata;
		}

		return result;
	}
};

/**
 * Get selected query/ scope by id
 * @param {*} id - selected id - scope_id | query_id
 * @param {*} options - config.scopeOptions | config.queryOptions
 */
export const getSelectedOptionById = (id, options) => {
	if (!arrayIsNullOrEmpty(options)) {
		return options.find((option) => option.value === id);
	}
};

/**
 * If entity does not exist in the list of current entities, id is valid
 * @param {*} currentEntities - mediaItem.scopes/queries
 * @param {*} id - selected id
 * @param {*} entityType - scope | query
 */
export const selectedIdIsValid = (currentEntities, id, entityType) => {
	const ids = currentEntities.map((entity) => entity[`${entityType}_id`]);
	return !ids.includes(id);
};

/**
 * Creates an empty workspace
 * @param {string} name
 * @param {number} workspace_id
 * @returns object
 */
export const createEmptyWorkspace = (name, workspace_id) => ({
	client_id: 0,
	contextual_summary: null,
	created_date: new Date().toISOString(),
	deleted_set_date: null,
	is_deleted: false,
	is_junk: false,
	item_date: null,
	junk_set_date: null,
	keyword_matches: [],
	name,
	sentiment: 'Neutral',
	triggered_by: {
		query_ids: [],
		scope_ids: []
	},
	workspace_id
});

/**
 * Builds Source Name for Media Type. Include the Program information for broadcast types
 * @param {string} source_name - Primary name of the source
 * @param {string} section_name - Name of the TV or Radio program
 * @param {string} media_type - type of the media item
 * @returns {string} Combined source name
 */
export const buildDefaultSourceName = (source_name, section_name, media_type) => {
	const sourceName = firstOrDefault(source_name, '');
	return isBroadcast(media_type) ? joinDefined([sourceName, section_name], ', ') : sourceName;
};
