import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import DoneIcon from '@mui/icons-material/Done';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import { variants } from './constants';

const Icon = ({ variant }) => {
	switch (variant) {
		case variants.disabled:
			return (
				<Tooltip title="This handle originates from Twitter/X and cannot be registered">
					<div className="social-handle-icon">
						<BlockOutlinedIcon />
					</div>
				</Tooltip>
			);
		case variants.registered:
			return (
				<Tooltip title="Registered">
					<div className="social-handle-icon">
						<DoneIcon />
					</div>
				</Tooltip>
			);
		case variants.unregistered:
			return (
				<Tooltip title="This handle has NOT been registered">
					<div className="social-handle-icon">
						<InfoOutlinedIcon />
					</div>
				</Tooltip>
			);
		default:
			throw new Error(`unknown variant '${variant}'`);
	}
};

export default Icon;
