import moment from 'moment';
import React from 'react';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import SourceLogo from '@truescope-web/react/lib/components/widgets/SourceLogo';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { buildDefaultSourceName } from '../../views/Content/MediaItem/MediaItemConstants';
import { createDataSource } from '../Dx/DxGridConstants';

export const createSearchDataSource = (
	getDatahubApi,
	onSearchStart,
	onSearchComplete,
	filter,
	annotation,
	scope,
	queryText,
	fields,
	useWhereClauses,
	requestParams
) => {
	return createDataSource({
		getDatahubApi,
		requestPath: '/documents/v1',
		convertItems: convertMediaItems,
		onSearchStart,
		onSearchComplete,
		filter,
		annotation,
		scope,
		queryString: queryText,
		fields,
		filterInclusions: ['sources'],
		useWhereClauses,
		requestParams
	});
};

/**
 * performs some custom conversions on the media items before sending them into the grid
 * @param {*} mediaItems
 */
const convertMediaItems = (mediaItems) => {
	return mediaItems.map((item) => {
		const { publication_date, ...other } = item;
		const publication_date__moment = moment(publication_date, `YYYY-MM-DD HH:mm:ssZ`).local();
		const { source_source_name, source_section_name, source_media_type } = item;

		return {
			...other,
			publication_date: publication_date__moment.toDate(),
			publication_date_string: publication_date__moment.format('DD/MM/YYYY'),
			publication_time_string: publication_date__moment.format('h:mmA'),
			primary_source_name: buildDefaultSourceName(source_source_name, source_section_name, source_media_type)
		};
	});
};

/**
 * renders the source with a title and logo
 * requires:
 * content_author
 * source_source_url
 * primary_source_name
 * @param {*} data
 */
export const renderSourceCell = ({ data }) => {
	const subtitle = stringIsNullOrEmpty(data.content_author) ? `No Author` : data.content_author;
	return (
		<div className="source-cell">
			{!isNullOrUndefined(data.source_profile_image_url) ? (
				<SourceLogo imageUrl={data.source_profile_image_url} sourceName={data.primary_source_name} subtitle={subtitle} />
			) : (
				<SourceLogo sourceUrl={data.source_source_url} sourceName={data.primary_source_name} subtitle={subtitle} />
			)}
		</div>
	);
};

/**
 * renders a date with the time underneath
 * COLUMN:		publication_date
 * date string: publication_date_string
 * time string: publication_time_string
 * @param {*} data
 */
export const renderDateCell = ({ data }) => {
	return (
		<div className="wrap">
			<Typography variant="h6">
				<b>{data.publication_date_string}</b>
			</Typography>
			<Typography variant="subtitle">{data.publication_time_string}</Typography>
		</div>
	);
};

/**
 * renders the title at the top, with the summary below.
 * title: title
 * summary: value
 * @param {*} param0
 */
export const renderSummaryCell = ({ data, value }) => {
	return (
		<div className="wrap">
			<Typography variant="h6" noWrap>
				<b>{data.title}</b>
			</Typography>
			<Typography variant="subtitle" noWrap>
				{value}
			</Typography>
		</div>
	);
};
