import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import Link from '@mui/material/Link';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { abbreviateNumber } from '@truescope-web/utils/lib/strings';
import { extractError, handleError } from '../../../components/Api';

/**
 * searches for owners based on a term
 * @param {*} param0
 * @param {*} query
 */
export const searchOwners = async (getDatahubApi, input) => {
	return getDatahubApi()
		.then((api) => api.post(`sources/v1/owners/`, { input }))
		.then(({ data }) => data)
		.catch((e) => ({ message: extractError(e), locations: [] }));
};

export const getSource = (getDatahubApi, sourceId) => {
	return getDatahubApi().then((api) =>
		api
			.get(`/sources/v1/${sourceId}`)
			.then(({ data }) => {
				let { source, authors, metadata, ...other } = data;

				return {
					source,
					authors,
					metadata,
					...other
				};
			})
			.catch((e) => handleError(`Failed to load source ${sourceId}`, e, { source: {} }))
	);
};

export const updateSource = (getDatahubApi, source, updates) => {
	let clone = {
		source_id: source.source_id,
		name: source.name,
		...updates
	};

	return getDatahubApi().then((api) =>
		api
			.patch('/sources/v1/', clone)
			.then(({ data }) => {
				return {
					success: data.success,
					source: {
						...source,
						...updates
					}
				};
			})
			.catch((e) => ({ message: extractError(e) }))
	);
};

export const createAuthorSectionFields = (sourceName) => {
	return [
		{
			label: 'Name',
			property: 'label',
			render: (value, data, _index) => {
				return <div className="capitalize">{data.label}</div>;
			}
		},
		{
			label: 'Articles',
			property: 'count',
			render: (value, data, _index) => {
				return (
					<Link
						className="clickable"
						href={`/media/search?query=${encodeURI(`author:"${data.label}" AND source_source_name:"${sourceName}"`)}`}
						target="_blank"
					>
						{value.toLocaleString()}
					</Link>
				);
			}
		}
	];
};

export const audiencesFields = [
	{
		label: 'Country',
		property: 'country',
		render: (value) => (value === 'GG' ? 'Global' : value)
	},
	{
		label: 'Audience',
		property: 'unique_visitors',
		render: (value) => abbreviateNumber(value)
	}
];

export const createSourceAudienceFields = (source) => {
	const hasAudienceOverride = !isNullOrUndefined(source.override_audience);
	const hasAudienceMultiplier = !hasAudienceOverride && !isNullOrUndefined(source.audience_multiplier);
	return {
		overrideAudience: hasAudienceOverride || hasAudienceMultiplier,
		overrideType: hasAudienceMultiplier ? audienceOverrideTypes.multiplier : audienceOverrideTypes.value,
		audienceValue: hasAudienceOverride ? source.override_audience : null,
		audienceMultiplier: hasAudienceMultiplier ? source.audience_multiplier : null
	};
};

export const audienceOverrideTypes = {
	value: 0,
	multiplier: 1
};

export const audienceOverrideTypeOptions = [
	{ label: 'By value', value: audienceOverrideTypes.value },
	{ label: 'By multiplier', value: audienceOverrideTypes.multiplier }
];

export const getDefaultAudience = (audiences) => {
	if (arrayIsNullOrEmpty(audiences)) {
		return 0;
	}

	return audiences[0].unique_visitors;
};

export const getMediaTypes = async (getDatahubApi) => {
	return getDatahubApi().then((api) =>
		api
			.get(`/sources/v1/mediaTypes`)
			.then(({ data }) => {
				return data;
			})
			.catch((e) => handleError(`Failed to load mediaTypes`, e))
	);
};

export const getSocialNetworks = async (getDatahubApi) => {
	return getDatahubApi().then((api) =>
		api
			.get(`/sources/v1/socialNetworks`)
			.then(({ data }) => {
				return data;
			})
			.catch((e) => handleError(`Failed to load socialNetworks`, e))
	);
};

export const createChannel = async (getDatahubApi, source, email) => {
	return getDatahubApi().then((api) =>
		api
			.put(`/sources/v1`, {
				source: {
					...source,
					source_reference: `User.${email}_${uuidv4()}`,
					date_created_utc: moment(new Date(), 'YYYY-MM-DD').utc().toISOString()
				}
			})
			.then(({ data }) => data)
			.catch((e) => ({ message: extractError(e) }))
	);
};

export const searchSources = async (getDatahubApi, queryText) => {
	return getDatahubApi().then((api) =>
		api
			.get(`/sources/v1/search?queryText=${encodeURIComponent(queryText)}`)
			.then(({ data }) => {
				return data;
			})
			.catch((e) => handleError(`Failed to get sources`, e))
	);
};

export const getSourceGeography = async (getDatahubApi, sourceId) => {
	try {
		const api = await getDatahubApi();
		const { data: sourceGeography } = await api.get(`/sources/v1/${sourceId}/geography`);
		return sourceGeography;
	} catch (e) {
		console.error(`failed to get source geography - ${e.message}`, e);
		return { countries: [], regions: [], cities: [] };
	}
};

export const updateSourceGeography = async (getDatahubApi, sourceGeography, sourceId) => {
	return getDatahubApi().then((api) =>
		api
			.patch(`/sources/v1/${sourceId}/geography`, sourceGeography)
			.then(({ data }) => data)
			.catch((e) => ({ message: extractError(e) }))
	);
};
